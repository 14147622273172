/* istanbul ignore file */

import {APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {enableProdMode, ErrorHandler, importProvidersFrom, Injectable, provideZoneChangeDetection} from '@angular/core';
import {MAT_AUTOCOMPLETE_DEFAULT_OPTIONS} from '@angular/material/autocomplete';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/datepicker';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER} from '@angular/material/menu';
import {MAT_SELECT_CONFIG, MAT_SELECT_SCROLL_STRATEGY_PROVIDER} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateFnsAdapter, MAT_DATE_FNS_FORMATS} from '@angular/material-date-fns-adapter';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, TitleStrategy} from '@angular/router';
import {AppComponent} from '@backoffice/components/app/app.component';
import {APP_ROUTES} from '@backoffice/components/app/app.routes';
import {AuthService} from '@backoffice/services/auth/auth.service';
import {CollectionsService} from '@backoffice/services/collections/collections.service';
import {GlobalMessageService} from '@backoffice/services/global-message/global-message.service';
import {HttpInterceptorService} from '@backoffice/services/http-interceptor/http-interceptor.service';
import {MenuRoutesService} from '@backoffice/services/menu-routes/menu-routes.service';
import {TagService} from '@backoffice/services/tag/tag.service';
import {UserDataService} from '@backoffice/services/user-data/user-data.service';
import {AddTagInterface} from '@common/components/add-tag/addTag.model';
import {CollectionsInterface} from '@common/components/collections/collections.model';
import {DATE_PIPE_CONFIG} from '@common/globals/datePipeConfig';
import {MAT_AUTOCOMPLETE_CONFIG} from '@common/globals/matAutocompleteConfig';
import {MAT_CHIP_CONFIG} from '@common/globals/matChipConfig';
import {MAT_DIALOG_CONFIG} from '@common/globals/matDialogConfig';
import {MAT_FORM_FIELD_CONFIG} from '@common/globals/matFormFieldConfig';
import {MAT_RIPPLE_CONFIG} from '@common/globals/matRippleConfig';
import {MAT_SELECT_DEFAULT_CONFIG} from '@common/globals/matSelectConfig';
import {NG_SCROLLBAR_CONFIG} from '@common/globals/ngScrollbarConfig';
import {SentryErrorHandler} from '@common/globals/sentryErrorHandler';
import appVersion from '@common/json/appVersion';
import {AutoFilterService} from '@common/services/auto-filter/auto-filter.service';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {CommonService} from '@common/services/common-service/common.service';
import {ConfirmService} from '@common/services/confirm-service/confirm.service';
import {FilterService} from '@common/services/filter/filter-service';
import {GridSearchService} from '@common/services/grid-search/grid-search.service';
import {ImageUploadService} from '@common/services/image-upload/image-upload.service';
import {LocalStorageService} from '@common/services/local-storage/local-storage.service';
import {VersionCheckService} from '@common/services/version-check/version-check.service';
import {initSentry} from '@common/statics/initSentry';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {
  ADJUSTMENTS,
  ARCHIVE_BOX_ARROW_DOWN,
  ARROW_CIRCLE_RIGHT,
  ARROW_LEFT,
  ARROW_RIGHT,
  BELL,
  BUILDING_STOREFRONT,
  CALENDAR,
  CAMERA,
  CHART_BAR,
  CHART_PIE,
  CHECK,
  CHECK_CIRCLE,
  CHECK_CIRCLE_SOLID,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_UP,
  CLIPBOARD_LIST,
  CLOCK,
  COG,
  CREDIT_CARD,
  CUBE,
  CURRENCY_POUND,
  DOCUMENT_ADD_SOLID,
  DOTS_HORIZONTAL,
  DOTS_VERTICAL,
  DUPLICATE,
  EXCLAMATION,
  EYE,
  EYE_SLASH,
  EYE_SOLID,
  FILTER,
  FLIP,
  HAND_SOLID,
  HASHTAG,
  INBOX,
  LOCATION_MARKER,
  MAIL,
  MENU,
  MINUS,
  MINUS_CIRCLE,
  PAPER_CLIP,
  PENCIL,
  PLAY,
  PLUS,
  PLUS_CIRCLE,
  QUESTION_MARK_CIRCLE,
  REFRESH,
  REPLY,
  ROTATE_CAMERA_LEFT,
  ROTATE_CAMERA_RIGHT,
  SAVE,
  SEARCH,
  SWITCH_VERTICAL,
  TAG,
  THUMB_UP_SOLID,
  TRASH,
  TRUCK,
  TRUCK_SOLID,
  USER,
  USER_ADD,
  USERS,
  VIEW_BOARDS,
  X,
  X_CIRCLE,
  X_CIRCLE_SOLID,
} from '@common/ts/heroIcons';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {PageTitleCommon} from '@common/ts/pageTitleCommon';
import {RouteCommon} from '@common/ts/routeCommon';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {enGB} from 'date-fns/locale';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NG_SCROLLBAR_OPTIONS} from 'ngx-scrollbar';

import {ENVIRONMENT} from './environments/environment';

if (ENVIRONMENT.production) {
  enableProdMode();
}

initSentry(ENVIRONMENT.sentryDns, appVersion.backoffice);

@Injectable()
class TemplatePageTitleStrategy extends PageTitleCommon {

  constructor() {
    super('Back office');
  }

}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: enGB},
    {provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: MAT_AUTOCOMPLETE_CONFIG},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAT_DIALOG_CONFIG},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MAT_RIPPLE_CONFIG},
    {provide: MAT_SELECT_CONFIG, useValue: MAT_SELECT_DEFAULT_CONFIG},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MAT_FORM_FIELD_CONFIG},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: DATE_PIPE_CONFIG},
    {provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: MAT_CHIP_CONFIG},
    {provide: NG_SCROLLBAR_OPTIONS, useValue: NG_SCROLLBAR_CONFIG},
    {provide: UserDataCommon, useExisting: UserDataService},
    {provide: GlobalMessageCommon, useExisting: GlobalMessageService, deps: [MatSnackBar]},
    {provide: CollectionsInterface, useExisting: CollectionsService},
    {provide: RouteCommon, useExisting: MenuRoutesService},
    {provide: AddTagInterface, useExisting: TagService},
    {provide: HI_ICON_SET_TOKEN, useValue: {
      arrowLeft: ARROW_LEFT,
      arrowRight: ARROW_RIGHT,
      bell: BELL,
      buildingStorefront: BUILDING_STOREFRONT,
      chevronLeft: CHEVRON_LEFT,
      chevronRight: CHEVRON_RIGHT,
      chevronDown: CHEVRON_DOWN,
      chevronUp: CHEVRON_UP,
      xCircleSolid: X_CIRCLE_SOLID,
      plus: PLUS,
      cog: COG,
      eye: EYE,
      eyeSlash: EYE_SLASH,
      save: SAVE,
      refresh: REFRESH,
      switchVertical: SWITCH_VERTICAL,
      search: SEARCH,
      documentAddSolid: DOCUMENT_ADD_SOLID,
      eyeSolid: EYE_SOLID,
      thumbUpSolid: THUMB_UP_SOLID,
      truckSolid: TRUCK_SOLID,
      handSolid: HAND_SOLID,
      checkCircleSolid: CHECK_CIRCLE_SOLID,
      calendar: CALENDAR,
      xCircle: X_CIRCLE,
      x: X,
      minus: MINUS,
      check: CHECK,
      filter: FILTER,
      locationMarker: LOCATION_MARKER,
      questionMarkCircle: QUESTION_MARK_CIRCLE,
      duplicate: DUPLICATE,
      trash: TRASH,
      currencyPound: CURRENCY_POUND,
      checkCircle: CHECK_CIRCLE,
      hashtag: HASHTAG,
      mail: MAIL,
      play: PLAY,
      pencil: PENCIL,
      reply: REPLY,
      truck: TRUCK,
      inbox: INBOX,
      tag: TAG,
      creditCard: CREDIT_CARD,
      cube: CUBE,
      user: USER,
      archiveBoxArrowDown: ARCHIVE_BOX_ARROW_DOWN,
      clipboardList: CLIPBOARD_LIST,
      users: USERS,
      userAdd: USER_ADD,
      chartPie: CHART_PIE,
      plusCircle: PLUS_CIRCLE,
      minusCircle: MINUS_CIRCLE,
      arrowCircleRight: ARROW_CIRCLE_RIGHT,
      clock: CLOCK,
      menu: MENU,
      chartBar: CHART_BAR,
      viewBoards: VIEW_BOARDS,
      adjustments: ADJUSTMENTS,
      flip: FLIP,
      rotateCameraRight: ROTATE_CAMERA_RIGHT,
      rotateCameraLeft: ROTATE_CAMERA_LEFT,
      paperClip: PAPER_CLIP,
      camera: CAMERA,
      dotsHorizontal: DOTS_HORIZONTAL,
      dotsVertical: DOTS_VERTICAL,
      exclamation: EXCLAMATION,
    }, multi: true},
    AuthService,
    MenuRoutesService,
    FilterService,
    CommonService,
    GlobalMessageService,
    LocalStorageService,
    provideAnimations(),
    UserDataService,
    VersionCheckService,
    GridSearchService,
    ConfirmService,
    CollectionsService,
    CanDeactivateService,
    AutoFilterService,
    ImageUploadService,
    TagService,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
    provideRouter(APP_ROUTES),
    importProvidersFrom(MatDialogModule),
    importProvidersFrom(NgxPageScrollCoreModule),
  ],
});
