import {Injectable} from '@angular/core';
import {AddTagInterface} from '@common/components/add-tag/addTag.model';
import {TagTypes} from '@common/enum/tagTypes.enum';
import {GridRequest} from '@common/globals/gridRequest';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {BulkTagGridRequestDTO, BulkTagRequestDTO, MessageResponseContext, TagDTO} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

import texts from './tagService';

@Injectable()
export class TagService extends AddTagInterface {

  getServicePath(type: TagTypes, isSearch = false): string {
    if (type === TagTypes.Location) {
      return `${BACKEND_URL}/backoffice/v1/bo/location/tag${isSearch ? '/prefix' : ''}`;
    }
  }

  getTagResponseMessages(type: TagTypes): MessageResponseContext {
    return setResponseMessage({
      message: `${TagTypes[type]} ${texts.tagSaveSuccess}`,
      errorMessage: texts.tagSaveFailed,
    });
  }

  saveTags(id: string, tags: TagDTO[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/sync?itemLocationId=${id}`, tags, this.getTagResponseMessages(type));
  }

  saveTagsBulk(tagTexts: string[], targetIds: string[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/add`, <BulkTagRequestDTO>{tagTexts, targetIds},
      this.getTagResponseMessages(type));
  }

  saveTagsBulkWithFilter(excludedIds: string[], filterRequest: GridRequest, tagTexts: string[], type: TagTypes): Observable<boolean> {
    return this.http.post<boolean>(`${this.getServicePath(type)}/add/filter`, <BulkTagGridRequestDTO>{excludedIds, filterRequest, tagTexts},
      this.getTagResponseMessages(type));
  }

  getTags(id: string, type: TagTypes): Observable<TagDTO[]> {
    return this.http.get<TagDTO[]>(`${this.getServicePath(type)}?itemLocationId=${id}`, setResponseMessage({
      errorMessage: texts.tagLoadFailed,
    }));
  }

  tagQuickSearch(tagText: string, type: TagTypes): Observable<TagDTO[]> {
    return this.http.get<TagDTO[]>(`${this.getServicePath(type, true)}?prefix=${tagText}`);
  }

}
